<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="primary" @click="navigateToCreateTask">Create Task</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="tasks"
          :search="search"
          class="elevation-1 mt-4"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2";
const service = new RestResource();

export default {
  data() {
    return {
      tasks: [],
      headers: [
        { text: "Task ID", value: "_id" },
        { text: "Task Name", value: "name.en" },
        { text: "Points", value: "points" },
        { text: "Description", value: "description" },
        { text: "Questions", value: "questions.length" },
        { text: "Render", value: "renderType" },
      ],
      search: ''
    };
  },

  mounted() {
    this.getTasks();
  },

  methods: {
    getTasks() {
      this.$setLoader()
      service.getTasks({ data: true }).then((r) => {
        this.tasks = r.data;
      });
      this.$disableLoader()
    },
    navigateToCreateTask() {
      this.$router.push({ name: 'MobileV2TasksCreate' });
    }
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}
.justify-end {
  justify-content: flex-end;
}
</style>
